import {createContext, useContext, useEffect, useState} from "react";
import {useRouter} from "next/router";
import {
	fetchToken,
	logout,
	registerUser,
	resendEmailVerifyRequest,
	sendEmailVerifyRequest,
	sendResetPassword,
	sendResetPasswordConfirm,
	updatePassword,
	updateUserPartial,
	userMe
} from "@/services/users";

import cookieCutter from 'cookie-cutter'

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

const {Provider} = AuthContext;


const AuthProvider = ({children}) => {
	const auth = useApiAuth()
	return (
		<Provider value={auth}>
			{children}
		</Provider>
	)
}

export async function setUserCookie(request, response) {
	const cookie = request.cookies['token']

	if (!cookie) {
		response.redirect("/account/auth")
	}

	return response
}

const getRole = (user) => {
	if (user?.is_superuser) {
		return "administrator"
	} else if (user?.is_collaborator) {
		return "collaborator"
	} else if (user?.is_manager) {
		return "manager"
	} else if (user?.is_staff) {
		return "staff"
	} else {
		return null
	}
}

export const formatAuthUser = (data) => {
	return {
		id: data.user?.id,
		email: data.user?.email,
		fullName: data.user?.first_name + " " + data.user?.last_name,
		first_name: data.user?.first_name,
		last_name: data.user?.last_name,
		role: getRole(data?.user),
		emailVerified: data?.user?.is_verified,
		token: data?.access,
		refresh: data?.refresh,
	};
};

const formatUser = (data) => {
	const permissions_codename = data?.permissions?.map((p) => p?.codename);

	return {
		id: data?.id,
		email: data?.email,
		fullName: data?.first_name + " " + data?.last_name,
		first_name: data?.first_name,
		last_name: data?.last_name,
		role: getRole(data),
		emailVerified: data?.is_verified,
		permissions:permissions_codename
	};
};

export default function useApiAuth() {
	const router = useRouter();
	const [authState, setAuthState] = useState(null);
	const [authError, setAuthError] = useState(null)
	const [authSuccess, setAuthSuccess] = useState(null)
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkAuthUser().then(r => console.log(r))
	}, [])


	const handleUser = (rawUser) => {
		if (rawUser) {
			const user = formatAuthUser(rawUser);
			const {token, refresh, ...userWithoutToken} = user;

			setAuthState({
				user: userWithoutToken,
				token: token
			});

			// if (remember_me && token) {
			// 	cookieCutter?.set('watchman-valid', 107, {
			// 		expires: new Date(parseInt(token_expires) * 1000)
			// 	});
			// } else {
			// 	cookieCutter?.set('watchman-valid', 101, {
			// 		expires: new Date(parseInt(token_expires) * 1000)
			// 	});
			// }
			setLoading(false);
			return user;

		} else {
			setAuthState({
				user: {},
				token: null
			});
			// cookieCutter?.set('watchman-valid', 0, {expires: new Date(0)})
			cookieCutter?.set('token', '', {expires: new Date(0)})
			cookieCutter?.set('user', '', {expires: new Date(0)})
			setLoading(false);
			return false;
		}
	};

	const isAuthenticated = () => {
		return !(authState === null);
	};

	const isAdmin = () => {
		return authState?.user?.role === "administrator"
	};

	const isStaff = () => {
		return authState?.user?.role === "staff"
	};

	const isManager = () => {
		return authState?.user?.role === "manager"
	};

	const isCollaborator = () => {
		return authState?.user?.role === "collaborator"
	};
	const userPermissions = () => {
		return authState?.user?.permissions
	};

	const checkAuthUser = async () => {
		await checkAuth()
	}

	const login = async (data) => {
		const {email, password} = data
		const resp = await fetchToken(email, password)
		if (resp.ok) {
			const resp_json = await resp?.json()
			if (typeof window !== 'undefined') {
				setAuthSuccess("Hello, welcome.")
			}
			const user = handleUser(resp_json.data)
			setLoading(false)
			setAuthSuccess("Hello, welcome.")
			return user
		} else {
			const resp_json = await resp?.json()
			setLoading(false)
			if (typeof window !== 'undefined') {
				setAuthError(resp_json.data)
			}
			setAuthError(resp_json.data)
			return resp_json
		}
	}

	const register_manager = async (data) => {
		const {first_name, last_name, email, password, terms_consent} = data
		return await registerUser(first_name, last_name, email, password, terms_consent, "manager")
	}

	const register_collaborator = async (data) => {
		const {first_name, last_name, email, password, terms_consent} = data
		return await registerUser(first_name, last_name, email, password, terms_consent, "collaborator")
	}

	const logoutUser = async () => {
		const resp = await logout()
		if (resp?.user?.status === "OK") {
			// cookieCutter?.set('watchman-valid', 0, {expires: new Date(0)})
			setAuthState(null);
			router.push("/account/auth")
		}
	}

	const checkAuth = async () => {
		const resp = await userMe()
		const user = resp?.user?.me
		if (user && Object.keys(user)?.includes("id")) {
			setAuthState({user: formatUser(user)})
			
			if (router.pathname.indexOf('/account/') !== -1) {
				router.push("/app/dashboard")
			}
			return formatUser(user)
		} else {
			if (router.pathname.indexOf('/app/dashboard') !== -1) {
				setAuthState(null)
				router.push("/account/auth")
			} else if (router.pathname.indexOf('/app/dashboard/') !== -1) {
				setAuthState(null)
				router.push("/account/auth")
			}
			else if (router.pathname.indexOf('/payment/') !== -1) {
				setAuthState(null)
				router.push("/account/auth")
			}
			// router.push("/account/auth")
			return null
		}
	}

	const updateProfile = async (id, data) => {
		setLoading(true)
		return await updateUserPartial(id, data)
	}

	const sendPasswordResetEmail = async (email) => {
		return await sendResetPassword(email)
	}

	const sendPasswordResetConfirm = async (resetToken, newPassword) => {
		return await sendResetPasswordConfirm(resetToken, newPassword)
	}

	const sendEmailVerify = async (verifyToken) => {
		return await sendEmailVerifyRequest(verifyToken)
	}

	const resendEmailVerify = async (email) => {
		return await resendEmailVerifyRequest(email)
	}

	const changePassword = async (token, id, old_passwd, new_password) => {
		return await updatePassword(token, id, old_passwd, new_password)
	}
	const checkPermissions = (requiredPermissions) => {
		const ret=requiredPermissions.every(permission => userPermissions()?.includes(permission))
		return ret ;
	  };
	  
	

	return {
		authState,
		isAdmin: isAdmin(),
		isStaff: isStaff(),
		isCollaborator: isCollaborator(),
		isManager: isManager(),
		setAuthState,
		isAuthenticated: isAuthenticated(),
		loading,
		authError,
		authSuccess,
		setAuthSuccess,
		setAuthError,
		setLoading,
		register_manager,
		register_collaborator,
		login,
		logoutUser,
		checkAuthUser,
		updateProfile,
		changePassword,
		sendPasswordResetEmail,
		sendPasswordResetConfirm,
		sendEmailVerify,
		resendEmailVerify,
		userPermissions,
		checkPermissions
	};
}

export {AuthContext, AuthProvider}