export const fetchToken = async (email, password) => {
	const url = "/api/account/authenticate/"
	return fetch(url, {
		method: "POST",
		body: JSON.stringify({
			"email": email,
			"password": password
		}),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	})
}

export const refreshNewToken = () => {
	return fetch("/api/account/refresh-token/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	});
};

export async function fetchUser(token) {
	return fetch("/api/account/current", {
		method: "GET",
		headers: new Headers({"Content-Type": "application/json", token}),
		credentials: "include",
	});
}

export const registerUser = async (first_name, last_name, email, password, terms_consent, type) => {
	const url = "/api/account/register"
	return fetch(url, {
		method: "POST",
		body: JSON.stringify({first_name, last_name, email, password, terms_consent, type}),
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	})
}

export async function userMe() {
	const url = '/api/account/me';
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: 'GET',
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		user: data,
		error
	};
}

export async function logout() {
	const url = `/api/account/logout/`;
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: 'POST',
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		user: data,
		error
	};
}

export async function updateUser(id, token, newUser) {
	const url = `/api/account/${id}`;
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: 'PUT',
			body: JSON.stringify(newUser),
			headers: new Headers({"Content-Type": "application/json", token}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		user: data,
		error
	};
}

export async function updateUserPartial(id, token, newUser) {
	const url = `/api/account/${id}`;
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: 'PATCH',
			body: JSON.stringify(newUser),
			headers: new Headers({"Content-Type": "application/json", token}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		user: data,
		error
	};
}

export async function deleteUser(id, token) {
	const url = `/api/account/${id}`;
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: 'DELETE',
			headers: new Headers({"Content-Type": "application/json", token}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		user: data,
		error
	};
}

export const sendResetPassword = async (email) => {
	const url = "/api/account/reset-password";
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: "POST",
			body: JSON.stringify({
				"email": email,
			}),
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		data,
		error
	};
}

export const sendResetPasswordConfirm = async (resetToken, newPassword) => {
	const url = "/api/account/confirm-reset";
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: "POST",
			body: JSON.stringify({
				"token": resetToken,
				"new_password": newPassword,
			}),
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		data,
		error
	};
}

export const sendEmailVerifyRequest = async (verifyToken) => {
	const url = "/api/account/verify-mail";
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: "POST",
			body: JSON.stringify({
				"token": verifyToken,
			}),
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		data,
		error
	};
}

export const resendEmailVerifyRequest = async (email) => {
	const url = "/api/account/resend-verify-mail";
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: "POST",
			body: JSON.stringify({
				"email": email,
			}),
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		data,
		error
	};
}

export const updatePassword = async (old_password, new_password) => {
	const url = "/api/account/change-password";
	let data = null;
	let error = ""
	let loaded = false

	try {
		const result = await fetch(url, {
			method: "PUT",
			body: JSON.stringify({old_password, new_password}),
			headers: new Headers({"Content-Type": "application/json"}),
			credentials: "include",
		});
		data = await result.json();
	} catch (e) {
		error = e
	} finally {
		loaded = true
	}

	const loading = !data && !error && !loaded;
	const unauthorized = error && error.status === 401;

	return {
		loading,
		unauthorized,
		data,
		error
	};
};
